<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
حسابي

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          
          <b-col md="6">
            <b-form-group
              label=" الاسم"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name=" Name"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addCourseForm.name" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group
              label="الايميل"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Teaching Field Name"
                  rules="required|email"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <b-col md="6">
            <b-form-group
              label="الدور"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               
               
              <b-form-input
              readonly
                id="blog-edit-slug"
                v-model="addCourseForm.role"
              />
             
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="saveInfo"
            >
            حفظ
            </b-button>
            </b-col>
            </b-row>
            <b-row>


              
              <b-col md="6">
                <b-form-group
                  label=" كلمة المرور الحالية"
                  label-for="blog-edit-title"
                  class="mb-2"
                >
                 <validation-provider
                      #default="{ errors }"
                      name=" Current Password"
                      rules="required"
                    >
                  <b-form-input id="blog-edit-title" v-model="addCourseForm.old_password" />
                     <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
             
            </b-row>
            <b-row>          <b-col md="6">
            <b-form-group
              label="كلمة المرور الجديدة "
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="New Password"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.new_password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
             <b-col md="6">
            <b-form-group
              label="تاكيد كلمة المرور الجديدة"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Confirm New Password"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.confirm_password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
          حفظ
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);

    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const categoriesList=ref([])
    store.dispatch('setting/getInfo')
        .then(response => {
          console.log("profil",response.data.data)
          addCourseForm.name=response.data.data.name
          addCourseForm.role=response.data.data.roles[0]
          addCourseForm.email=response.data.data.email
        })
 store.dispatch('categories/AllCategory')
        .then(response => {
          console.log("response",response.data.data)
          categoriesList.value=response.data.data
        })
    const addCourseForm = reactive({
      new_password: "",
      name: "",
      email: "",
      role:'',
     
      old_password: "",
      confirm_password: "",
  
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const addCourseFormvalidate=ref()
     
     const saveInfo = () => {
      
      console.log(addCourseForm)
      const formData = new FormData();

          formData.append("email", addCourseForm.email);
formData.append("name", addCourseForm.name);


      console.log("formData",formData)
           store.dispatch('setting/UpdateInfo',formData)
        .then(response => {
           Vue.swal({
            icon: "success",
              title: "تم التحديث",
              confirmButtonText: "حسنا",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "حسنا",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
   
    };
    const save = () => {
      
      console.log(addCourseForm)
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("new_password", addCourseForm.new_password);
formData.append("confirm_password", addCourseForm.confirm_password);
formData.append("old_password", addCourseForm.old_password);

      console.log("formData",formData)
           store.dispatch('setting/UpdatePassowrd',formData)
        .then(response => {
           Vue.swal({
            icon: "success",
              title: "تم التحديث",
              confirmButtonText: "حسنا",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "حسنا",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addCourseForm);
        }})
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
     
      saveInfo,
      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>

  .mt-50.col-12 {
   margin: 21px 0px;
}
</style>